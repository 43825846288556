<template>
  <div style="min-height: 500px">
    <div class="row pb-2 pt-2">
      <div class="col-sm-7 mr-0">
        <main-slider/>
      </div>
      <div class="col-sm-5 ml-0 mr-0">
        <b-card no-body class="overflow-hidden" style=" background-color: rgba(29,29,21)">
          <b-row no-gutters>
            <b-col md="8">
              <b-card-body class="text-blue">
                <b-card-title class="c_color"><b>Headmaster</b></b-card-title>
                <b-card-text class="text-custom" v-html="principalMessage.description?principalMessage.description.slice(0,400):principalMessage.description">
                </b-card-text>
                <router-link :to="{name:'principalMessage'}">
                  <span class="c_color"><strong>Read More >></strong></span>
                </router-link>
              </b-card-body>
            </b-col>
            <b-col md="3">
              <b-card-img :src="imgShow(principalMessage.image)" alt="Headmaster" class=" thumbnail fluid rounded-0 mt-5"></b-card-img>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <div class="mt-4">
      <h2 class="divider gradient"  id="m-on" contenteditable></h2>
      <b-row id="m-off">
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
      </b-row>
      <Marque/>
      <h2 class="divider gradient"  id="m-on" contenteditable></h2>
      <b-row id="m-off">
        <b-col md="1"></b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="1"></b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="1"></b-col>
        <b-col md="2">
          <h2 class="divider gradient" contenteditable></h2>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
    </div>
    <b-row class="mt-4 col-md-12  ml-0 mr-0">
      <b-col md="3">
        <SidebarLeft/>
      </b-col>
      <b-col md="6">
        <slider2 :sliders="news_sliders"/>
      </b-col>
      <b-col md="3">
        <sidebar-right/>
      </b-col>
    </b-row>
    <h4 class="c_color mt-4 text-center" ><strong><b>OUR SPOTLIGHTS</b></strong></h4>
    <div class="row mt-4 slide_background">
      <div class="col-sm-6 mb-4 ">
        <h4 class="c_color mb-3 mt-3 text-center">
          <span class="slide-heading"><strong>COMMON EVENTS</strong></span></h4>
        <slider :sliders="boy_wing_sliders"/>
      </div>
      <div class="col-sm-6 mb-4">
        <h4 class="c_color mt-3 mb-3 text-center"><span class="slide-heading"><strong>CLASS PROGRAM</strong></span></h4>
        <slider :sliders="girl_wing_sliders"/>
      </div>
    </div>

  </div>

</template>

<script>
import MainSlider from "./pages/home/MainSlider";
import Slider from "./pages/home/Slider";
import SidebarLeft from "./layout/SidebarLeft";
import SidebarRight from "./layout/SidebarRight";
import Slider2 from "./pages/home/Slider2";
import Marque from "./pages/home/Marque";
import apiCall from "../core/apiCall";
import {mapGetters} from "vuex"

export default {
  name: 'Home',
  components:{
    Slider,SidebarLeft,SidebarRight,
    Slider2,Marque,
    MainSlider,
  },
  data(){
    return{
      principalMessage:{},
    }
  },
  methods:{
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    async getMessage(){
      this.$store.commit('LOADER_STATUS_CHANGE',true);
      await apiCall.get('/public/site/principal/message').then((response)=>{
        this.$store.commit('LOADER_STATUS_CHANGE',false);
        this.principalMessage=response.data;
      }).catch(()=>{
        this.$store.commit('LOADER_STATUS_CHANGE',false);
        this.principalMessage={};
      
      })
    },
  },
  created() {
    this.getMessage();
    if(this.sliders.length <1) this.$store.dispatch('GET_ALL_SLIDER');
  },
  computed:{
    ...mapGetters(['main_sliders','sliders','news_sliders','boy_wing_sliders','girl_wing_sliders']),
    loader() {
      return this.$store.getters.loader;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c_color{
  color: rgba(112, 131, 53);
}
.slide_background{
  background-color: rgba(58, 61, 51);
}
.slide-heading{
  margin: 0 auto;
  padding: 6px 60px 6px 60px;
  text-align: center;
  /* border-radius */
  border-radius: 2px;
  /* box-shadow */
  box-shadow: rgba(109, 113, 77) 1px  1px 0 2px;
  border-collapse: collapse;
}
.text-custom{
  color: rgba(123,127,109);
  text-align: justify;
}
.divider {
  display: flex;
}
.divider:before,
:after {
  content: "";
  flex: 1;
}
.gradient {
  align-items: stretch;
  height: 2px;
  line-height: 1em;
  color: rgba(29,28,22);
  background: rgba(75,75,47);
}
.gradient:before {
  background: linear-gradient(to right, rgba(29,28,22), rgba(75,75,47));
}
.gradient:after {
  background: linear-gradient(to left, rgba(29,28,22), rgba(75,75,47));
}
@media screen and (max-width: 600px) {
  #m-off {
    visibility: hidden;
    clear: both;
    display: none;
  }
}
@media screen and (min-width: 780px) {
  #m-on {
    visibility: hidden;
    clear: both;
    display: none;
  }
}
</style>

